import DOMPurify from "isomorphic-dompurify";
import moment from "moment";
import trim from "lodash/trim";
import { getConfiguration } from "page-services/configuration.service";
import ServerURL from "url";
import { isOnBrowser } from "./browser";
import { getBuddhistDate } from "./buddhistDate";
import { CONTENSTACK_FILE_URL, CONTENSTACK_IMAGE_URL } from "./relativePaths";

const URLParser = URL || ServerURL;

const CONSTANT = {
  LINE_BREAK_TAG_ALIAS: "__br__",
  LINE_BREAK_TAG: "<br>",
};

const TEXT_CONTENT_REGEX = {
  LINE_BREAK: /\\n/g,
  // [I'm a link](https://www.google.com)
  MARKDOWN_LINK: /\[([^\]]+)\]\(([^\)]+)\)/,
  EXTERNAL_URL: /^https?:\/\//,
  // match all starts with and ends with <br> tag
  LINE_BREAK_TAG_START_END:
    /^(\s*<br\s*\/?\s*>\s*)*|(\s*<br\s*\/?\s*>\s*)*\s*$/g,
  HTML_TAG_CONTENT: /<[^>]+>/g,
};

function capitalizeFirstLetter(val: string): string {
  if (val) {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }
  return "";
}

function capitalizeStr(str: string): string {
  var regex = /\b[a-z]/g;
  str = str.toLowerCase().replace(regex, function (letter) {
    return letter.toUpperCase();
  });
  return str;
}

function getFormatDate(
  date?: string,
  languageCode?: string,
  countryCode?: string,
  customFormat?: string
): string {
  if (!date) return "";
  if (customFormat) {
    return moment(date).format(customFormat);
  }

  const defaultFormat = "DD/MM/YYYY";

  switch (languageCode) {
    case "th":
      return getBuddhistDate(date, defaultFormat);
    case "ph":
    case "my":
    case "my-capricorn":
    case "en":
    case "my-capricorn":
    case "cn": {
      let formattedDate = "";

      switch (countryCode) {
        case "ph":
          formattedDate = moment(date).format("MMMM DD, yyyy");
          break;
        case "go":
        case "hk":
        case "id":
        case "my":
        case "my-capricorn":
        case "vn":
        case "kh":
        case "my-capricorn":
          formattedDate = moment(date).format("DD MMMM yyyy");
          break;
        default:
          formattedDate = moment(date).format(defaultFormat);
          break;
      }

      return formattedDate;
    }
    case "ja":
    case "zh":
    case "tc":
      const lang = "en";
      const dt = new Date(date);
      const year = dt.toLocaleString(lang, { year: "numeric" }),
        month = dt.toLocaleString(lang, { month: "numeric" }),
        day = dt.toLocaleString(lang, { day: "numeric" });
      return `${year}年${month}月${day}日`;
    case "km":
      return moment(date).locale("km").format("LL");
    default:
      if (languageCode === "id" && countryCode === "id") {
        return moment(date).locale("id").format("LL");
      }

      return moment(date).format(defaultFormat);
  }
}

function isFile(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg|pdf|docx|doc|xlsx|xml)$/.test(url);
}

function extractValueFromTag(str, tag) {
  let strWithoutTag = str;
  const subStrIdx = str?.indexOf(tag);
  let subStr = "";
  if (subStrIdx >= 0) {
    const subTitleWithTag = str.substring(subStrIdx, str.length);
    strWithoutTag = str.substring(0, subStrIdx);
    subStr =
      subTitleWithTag?.length > 0 ? DOMPurify.sanitize(subTitleWithTag) : "";
    subStr = subStr.replaceAll(tag, "");
  }
  return { strWithoutTag, subStr };
}

export function getFundFormatDate(
  date?: string,
  languageCode?: string,
  countryCode?: string,
  customFormat?: string
): string {
  if (!date) return "";
  const defaultFormat = "DD/MM/YYYY";

  if (languageCode === "th") {
    return getBuddhistDate(date, defaultFormat);
  } else if (["ph", "my", "en", "cn"].includes(languageCode || "")) {
    switch (countryCode) {
      case "ph":
        return moment(date).format("MMMM DD, yyyy");
      case "go":
      case "id":
      case "my":
      case "vn":
      case "kh":
        return moment(date).format("DD MMMM yyyy");
      case "hk":
        return moment(date, defaultFormat).format(
          customFormat || defaultFormat
        );
      default:
        return moment(date, defaultFormat).format(defaultFormat);
    }
  } else if (["ja", "zh", "tc"].includes(languageCode || "")) {
    if (["hk", "mo"].includes(countryCode || "")) {
      const dt = moment(date, defaultFormat).locale("zh_cn");
      return dt.format("LL");
    }
    const lang = "en";
    const dt = new Date(date);
    const year = dt.toLocaleString(lang, { year: "numeric" }),
      month = dt.toLocaleString(lang, { month: "numeric" }),
      day = dt.toLocaleString(lang, { day: "numeric" });
    return `${year}年${month}月${day}日`;
  } else if (languageCode === "km") {
    return moment(date).locale("km").format("LL");
  } else {
    return moment(date).format(defaultFormat);
  }
}

const transformTextContent = (
  text?: string,
  options?: {
    from?: string | RegExp;
    to?: string;
  }
) => {
  if (!text) {
    return text;
  }

  const { from = /\\n/g, to = "<br/>" } = options || {};

  return text.replace(from, to);
};

const transformLineBreak = (text?: string) =>
  transformTextContent(text, {
    from: TEXT_CONTENT_REGEX.LINE_BREAK,
    to: "<br/>",
  });

const transformLink = (text?: string) =>
  transformTextContent(text, {
    from: TEXT_CONTENT_REGEX.MARKDOWN_LINK,
    to: '<a href="$2">$1</a>',
  });

const transformNewTabLink = (text?: string) =>
  transformTextContent(text, {
    from: TEXT_CONTENT_REGEX.MARKDOWN_LINK,
    to: '<a target="_blank" rel="noopener noreferrer" href="$2">$1</a>',
  });

export const isExternalUrl = (url?: string) => {
  if (!url) {
    return false;
  }

  return url.match(TEXT_CONTENT_REGEX.EXTERNAL_URL) || url.startsWith("www.");
};

const isNotRouteUrl = (url?: string) => {
  if (!url) {
    return false;
  }

  return ["tel:", "mailto:"].some((t) => url.startsWith(t));
};

export const processUrl = (
  url: string | undefined,
  processUrl: (url: string) => string
) => {
  const isContentStackAssetUrl =
    url?.startsWith(CONTENSTACK_FILE_URL) ||
    url?.startsWith(CONTENSTACK_IMAGE_URL);

  if (
    (!isContentStackAssetUrl && isExternalUrl(url)) ||
    !url ||
    isNotRouteUrl(url)
  ) {
    return url || "";
  }

  return processUrl(url);
};

const formatCMSUrl = (url?: string, locale?: string | null) => {
  if (
    isExternalUrl(url) ||
    !url ||
    url.startsWith(`/${locale}`) ||
    isNotRouteUrl(url)
  ) {
    return url || "";
  }

  const finalUrl = url.startsWith("/") ? url : `/${url}`;
  const localeUrl = locale ? `/${locale}` : "";

  return localeUrl + finalUrl;
};

const extractHtmlTextContent = (htmlString: string) => {
  if (!htmlString) {
    return "";
  }

  if (!isOnBrowser()) {
    return htmlString.replace(TEXT_CONTENT_REGEX.HTML_TAG_CONTENT, "");
  }

  const div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent || div.innerText;
};

// get text content of html string with line break
const extractHtmlTextWithLineBreak = (htmlString: string) => {
  if (!htmlString) {
    return "";
  }

  const parsed = extractHtmlTextContent(
    htmlString.replaceAll(
      CONSTANT.LINE_BREAK_TAG,
      CONSTANT.LINE_BREAK_TAG_ALIAS
    )
  );

  if (!parsed) {
    return "";
  }

  return parsed
    .replaceAll(CONSTANT.LINE_BREAK_TAG_ALIAS, CONSTANT.LINE_BREAK_TAG)
    .replaceAll(TEXT_CONTENT_REGEX.LINE_BREAK_TAG_START_END, "");
};

const sanitizeHtml = (
  htmlString: string | undefined,
  transformers?: ((htmlString?: string) => string | undefined)[],
  allowAttributes?: string[]
) => {
  if (!htmlString) {
    return "";
  }

  let transformedHtml = htmlString;

  if (transformers) {
    transformedHtml = transformers.reduce(
      (acc, transformer) => transformer(acc) || "",
      htmlString
    );
  }

  return DOMPurify.sanitize(transformedHtml, {
    ADD_ATTR: allowAttributes || [],
  });
};

const extractValueFromMainTitle = (title: string) => {
  const tag = "<main>";
  const isMainTitle = !!title ? title?.indexOf(tag) >= 0 : false;
  const mainTitle = isMainTitle ? title?.replaceAll(tag, "") : title;
  return {
    mainTitle,
    isMainTitle,
  };
};

const extractValueFromTagTitle = (title: string, tagName: string) => {
  const tag = !!tagName ? `<${tagName}>` : "";
  const isHasTag = !!title && !!tagName ? title?.indexOf(tag) >= 0 : false;
  const extractTitle = isHasTag ? title?.replaceAll(tag, "") : title;
  return {
    extractTitle,
    isHasTag,
    tagName,
  };
};

const parseUrl = (url: string): URL => {
  if (isExternalUrl(url)) {
    return new URLParser(url);
  }

  return new URLParser(
    url,
    getConfiguration(
      "NEXT_PUBLIC_EXPORT_SITE",
      process.env.NEXT_PUBLIC_EXPORT_SITE
    )
  );
};

const sanitizeHtmlDefault = (htmlString: string) => {
  if (!htmlString) {
    return "";
  }

  return DOMPurify.sanitize(htmlString, {
    ALLOWED_TAGS: ["p", "span", "div", "b", "p", "ul", "li", "ol", "br", "a"],
  });
};

const removeHtmlTags = (text) => {
  return typeof text === "string"
    ? trim(text.replace(/<\/?[^>]+(>|$)/g, ""))
    : text;
};

export const isValidRegex = (regex?: string) => {
  if (!regex) {
    return false;
  }

  let isValid = true;
  try {
    new RegExp(regex);
  } catch (e) {
    isValid = false;
  }

  return isValid;
};

export const isMatchStringRegex = (
  stringRegex?: string,
  stringToMatch?: string
) => {
  if (!stringRegex || !stringToMatch || !isValidRegex(stringRegex)) {
    return false;
  }

  return new RegExp(stringRegex).test(stringToMatch);
};

/**
 *
 * Secure Hash Algorithm (SHA256)
 */
export const SHA256 = (s) => {
  var chrsz = 8;

  var hexcase = 0;

  function safe_add(x, y) {
    var lsw = (x & 0xffff) + (y & 0xffff);

    var msw = (x >> 16) + (y >> 16) + (lsw >> 16);

    return (msw << 16) | (lsw & 0xffff);
  }

  function S(X, n) {
    return (X >>> n) | (X << (32 - n));
  }

  function R(X, n) {
    return X >>> n;
  }

  function Ch(x, y, z) {
    return (x & y) ^ (~x & z);
  }

  function Maj(x, y, z) {
    return (x & y) ^ (x & z) ^ (y & z);
  }

  function Sigma0256(x) {
    return S(x, 2) ^ S(x, 13) ^ S(x, 22);
  }

  function Sigma1256(x) {
    return S(x, 6) ^ S(x, 11) ^ S(x, 25);
  }

  function Gamma0256(x) {
    return S(x, 7) ^ S(x, 18) ^ R(x, 3);
  }

  function Gamma1256(x) {
    return S(x, 17) ^ S(x, 19) ^ R(x, 10);
  }

  function core_sha256(m, l) {
    var K = new Array( // eslint-disable-line no-array-constructor
      0x428a2f98,
      0x71374491,
      0xb5c0fbcf,
      0xe9b5dba5,
      0x3956c25b,
      0x59f111f1,
      0x923f82a4,
      0xab1c5ed5,
      0xd807aa98,
      0x12835b01,
      0x243185be,
      0x550c7dc3,
      0x72be5d74,
      0x80deb1fe,
      0x9bdc06a7,
      0xc19bf174,
      0xe49b69c1,
      0xefbe4786,
      0xfc19dc6,
      0x240ca1cc,
      0x2de92c6f,
      0x4a7484aa,
      0x5cb0a9dc,
      0x76f988da,
      0x983e5152,
      0xa831c66d,
      0xb00327c8,
      0xbf597fc7,
      0xc6e00bf3,
      0xd5a79147,
      0x6ca6351,
      0x14292967,
      0x27b70a85,
      0x2e1b2138,
      0x4d2c6dfc,
      0x53380d13,
      0x650a7354,
      0x766a0abb,
      0x81c2c92e,
      0x92722c85,
      0xa2bfe8a1,
      0xa81a664b,
      0xc24b8b70,
      0xc76c51a3,
      0xd192e819,
      0xd6990624,
      0xf40e3585,
      0x106aa070,
      0x19a4c116,
      0x1e376c08,
      0x2748774c,
      0x34b0bcb5,
      0x391c0cb3,
      0x4ed8aa4a,
      0x5b9cca4f,
      0x682e6ff3,
      0x748f82ee,
      0x78a5636f,
      0x84c87814,
      0x8cc70208,
      0x90befffa,
      0xa4506ceb,
      0xbef9a3f7,
      0xc67178f2
    );

    var HASH = new Array( // eslint-disable-line no-array-constructor
      0x6a09e667,
      0xbb67ae85,
      0x3c6ef372,
      0xa54ff53a,
      0x510e527f,
      0x9b05688c,
      0x1f83d9ab,
      0x5be0cd19
    );

    var W = new Array(64);

    var a, b, c, d, e, f, g, h;

    var T1, T2;

    m[l >> 5] |= 0x80 << (24 - (l % 32));

    m[(((l + 64) >> 9) << 4) + 15] = l;

    for (var i = 0; i < m.length; i += 16) {
      a = HASH[0];

      b = HASH[1];

      c = HASH[2];

      d = HASH[3];

      e = HASH[4];

      f = HASH[5];

      g = HASH[6];

      h = HASH[7];

      for (var j = 0; j < 64; j++) {
        if (j < 16) W[j] = m[j + i];
        else
          W[j] = safe_add(
            safe_add(
              safe_add(Gamma1256(W[j - 2]), W[j - 7]),
              Gamma0256(W[j - 15])
            ),
            W[j - 16]
          );

        T1 = safe_add(
          safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]),
          W[j]
        );

        T2 = safe_add(Sigma0256(a), Maj(a, b, c));

        h = g;

        g = f;

        f = e;

        e = safe_add(d, T1);

        d = c;

        c = b;

        b = a;

        a = safe_add(T1, T2);
      }

      HASH[0] = safe_add(a, HASH[0]);

      HASH[1] = safe_add(b, HASH[1]);

      HASH[2] = safe_add(c, HASH[2]);

      HASH[3] = safe_add(d, HASH[3]);

      HASH[4] = safe_add(e, HASH[4]);

      HASH[5] = safe_add(f, HASH[5]);

      HASH[6] = safe_add(g, HASH[6]);

      HASH[7] = safe_add(h, HASH[7]);
    }

    return HASH;
  }

  function str2binb(str) {
    var bin = Array(); // eslint-disable-line no-array-constructor

    var mask = (1 << chrsz) - 1;

    for (var i = 0; i < str.length * chrsz; i += chrsz) {
      bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - (i % 32));
    }

    return bin;
  }

  function Utf8Encode(string) {
    if (!string) return "";

    string = string.replace(/\r\n/g, "\n");

    var utftext = "";

    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);

        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);

        utftext += String.fromCharCode(((c >> 6) & 63) | 128);

        utftext += String.fromCharCode((c & 63) | 128);
      }
    }

    return utftext;
  }

  function binb2hex(binarray) {
    var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";

    var str = "";

    for (var i = 0; i < binarray.length * 4; i++) {
      str +=
        hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf) +
        hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
    }

    return str;
  }

  s = Utf8Encode(s);

  return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
};

const getCountryCode = (countryCode?: string) => {
  if (!countryCode) {
    return countryCode;
  }

  if (countryCode.includes("-")) {
    return countryCode.split("-")[0];
  }

  return countryCode;
};

const replaceBreakLine = (text: string) => {
  return typeof text === "string"
    ? text.replace(new RegExp("\\\\n", "g"), "<br />")
    : text;
};

const updateRichText = (text: string) => {
  return (
    text.replace(/[<>\\]/g, function (e) {
      switch (e) {
        case "<":
          return "<span>";
        case ">":
          return "</span>";
        case "\\":
          return "<br/>";
        default:
          return e;
      }
    }) || ""
  );
};

const removeTrailingSlash = (url: string) => {
  if (!url) {
    return url;
  }

  if (url.endsWith("/")) {
    return url.slice(0, -1);
  }

  return url;
};

const genDescriptionWithGenderAndAge = (
  description: string,
  replacements: { [key: string]: string | number }
): string => {
  return description.replace(
    /{{(gender|age)}}/g,
    (_, key) => `${replacements[key]}`
  );
};

const genDescriptionWithGenderAndAgeHolder = (
  description: string,
  gender: string,
  age: string
): string => {
  return description.replace(/{{(gender|age):([^}]+)}}/g, (match, p1, p2) => {
    switch (p1) {
      case "gender":
        return gender && gender !== "None" ? gender : p2;
      case "age":
        return age && age !== "0" ? age.toString() : p2;
      default:
        return match;
    }
  });
};

const localeStringToNumber = (localeString: string) => {
  return parseFloat(localeString.replace(/,/g, ""));
};

export {
  capitalizeFirstLetter,
  capitalizeStr,
  extractHtmlTextWithLineBreak,
  extractValueFromMainTitle,
  extractValueFromTag,
  extractValueFromTagTitle,
  formatCMSUrl,
  getFormatDate,
  isFile,
  parseUrl,
  sanitizeHtml,
  sanitizeHtmlDefault,
  transformLineBreak,
  transformLink,
  transformNewTabLink,
  removeHtmlTags,
  getCountryCode,
  replaceBreakLine,
  updateRichText,
  removeTrailingSlash,
  genDescriptionWithGenderAndAge,
  genDescriptionWithGenderAndAgeHolder,
  localeStringToNumber,
};
