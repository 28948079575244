import { getConfiguration } from "page-services/configuration.service";

export const fallbackContentTypeTemplates = [
  "oneweb_homepage",
  "claims",
  "support",
  "brand",
  "search",
  "fund_price",
  "oneweb_product_listing",
  "product_details",
  "article_listing",
  "articles_details",
  "group_insurance_product_listing",
  "flexible",
  "sitemap_page",
  "single_page_enquiry",
  "corp_category_landing_page",
];

export const fallbackAdditionalContentTypeTemplates = [
  "splash",
  "oneweb_announcement",
];

export const fallbackSiteSettingsReferences = [
  "error_pages.body",
  "error_pages.footer.country_list",
  "error_pages.header.country_list",
  "error_pages.header.navigation_sections.major_grouping.disclosure_popup",
];

export const additionalContentTypeTemplates = () => {
  const data = getConfiguration(
    "ADDITIONAL_CONTENTSTACK_PAGE_CONTENT_UIDS",
    process.env.ADDITIONAL_CONTENTSTACK_PAGE_CONTENT_UIDS
  );

  if (!!data) {
    return data.split(",")?.filter((item) => !!item) || [];
  }

  if (data === "none") {
    return [];
  }

  return fallbackAdditionalContentTypeTemplates;
};

export const contentTypeTemplates = () => {
  const data = getConfiguration(
    "UI_CONTENTSTACK_PAGE_CONTENT_UIDS",
    process.env.UI_CONTENTSTACK_PAGE_CONTENT_UIDS
  );

  if (!!data) {
    return data.split(",")?.filter((item) => !!item) || [];
  }

  return fallbackContentTypeTemplates;
};

export const siteSettingsReferences = () => {
  const data = getConfiguration(
    "SITE_SETTINGS_REFERENCES",
    process.env.SITE_SETTINGS_REFERENCES
  );

  if (!!data) {
    return data.split(",")?.filter((item) => !!item) || [];
  }

  return fallbackSiteSettingsReferences;
};
