import { getWindowHref, getWindowOrigin, replaceContentStackURL } from "@utils";
import { Environment } from "constants/pages.constants";
import { get } from "lodash";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import Head from "next/head";
import { useRouter } from "next/router";
import { getCurrentEnvironment } from "page-services/helper.service";
import React, { useEffect, useMemo } from "react";
import { PageType } from "types/PageType.enum";
import gtmUtils from "utils/gtm";

export interface HeaderMetadataData {
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  ld_json: any;
  canonical_url: string;
}

export interface HeaderMetadataProps {
  data: {
    seo: HeaderMetadataData;
    openGraph: {
      og_title: string;
      og_description: string;
      og_url: string;
      og_image: { url?: string };
    };
    pageType?: any;
    body?: any;
  };
  supportedLanguages?: Record<
    string,
    { locale: string; languageCode: string; displayName: string }
  >;
  publicExportSite?: string;
}

const isValidCanonicalizeUrl = (url: string): boolean =>
  /^(http|https).*/.test(url);

function canonicalizeUrl(url?: string): string {
  if (!url) return "";
  if (/^(http|https).*/.test(url)) return url;
  if (url.endsWith("/")) {
    url = url.slice(0, url.length - 1);
  }

  if (url.startsWith("/")) return url;

  return `/${url}`;
}

function appendOrigin(url?: string, publicExportSite?: string): string {
  if (!url) return "";
  if (/^(http|https).*/.test(url)) return url;

  if (!url.startsWith("/")) {
    url = `/${url}`;
  }

  const windowOrigin = getWindowOrigin();
  const origin = publicExportSite || windowOrigin;

  return `${origin}${url}`;
}

export const HeaderMetadata: React.FC<HeaderMetadataProps> = ({
  data,
  supportedLanguages,
  publicExportSite,
}) => {
  const { meta_title, meta_description, meta_keywords, ld_json } =
    data.seo || {};
  const { og_title, og_description, og_url, og_image } = data.openGraph || {};
  const { pageType, body } = data;
  const router = useRouter();
  const { lang } = router.query || "";
  const processLang = !!lang && lang.length > 2 ? "" : `/${lang}`;
  const process_og_url = useMemo(() => {
    let canonicalUrl = canonicalizeUrl(og_url);
    if (canonicalUrl && isValidCanonicalizeUrl(canonicalUrl))
      return canonicalUrl;

    const canonicalLangCode =
      supportedLanguages && size(supportedLanguages) > 1 ? processLang : "";
    return `${
      getWindowOrigin() || publicExportSite
    }${canonicalLangCode}${canonicalUrl}`;
  }, [og_url, supportedLanguages]);

  const process_canonical_url = useMemo(() => {
    const mapUrl = process_og_url.endsWith("/")
      ? process_og_url
      : `${process_og_url}/`;
    const fullUrl = `${getWindowOrigin() || publicExportSite}${router.asPath}`;
    if (fullUrl.includes(mapUrl)) return fullUrl;
    return mapUrl;
  }, [process_og_url, router.asPath]);

  const og_image_url = useMemo(() => {
    return appendOrigin(
      replaceContentStackURL(og_image?.url || "") || "",
      publicExportSite
    );
  }, [og_image?.url]);

  const twitter_og_image_url = useMemo(() => {
    return appendOrigin(
      replaceContentStackURL(og_image?.url || "") || "",
      publicExportSite
    );
  }, [og_image?.url]);

  const currentEnv = useMemo(() => {
    return getCurrentEnvironment();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const getType = () => {
        switch (pageType) {
          case PageType.Claims:
            return "claim_content";
          case PageType.Products:
            return "Product";
          case PageType.Articles:
            return "article_content";
          default:
            return pageType || "";
        }
      };

      const getTags = () => {
        switch (pageType) {
          case PageType.Products:
            const productMetaData = body?.find(
              (e: any) => e.product_metadata
            )?.product_metadata;
            const tags = (productMetaData?.product_tags?.value || []).map(
              (tag: any) => tag?.value
            );
            return tags;
          default:
            return [];
        }
      };

      const gtmData = {
        event: gtmUtils.gaEvent.content_landing,
        content: {
          type: getType(),
          title: meta_title,
          url: getWindowHref(),
          description: meta_description,
          keywords: meta_keywords,
          author: undefined,
          author_city: undefined,
          publish_time: undefined,
          label: "content_label",
          tags: getTags(),
        },
      };

      if (pageType === PageType.Articles) {
        const articleData = body?.find((e: any) => e.article_block)
          ?.reference?.[0];

        if (articleData) {
          gtmData.content.author = articleData?.author;
          gtmData.content.author_city = articleData?.location;
          gtmData.content.publish_time = articleData?.date;
          gtmData.content.tags = articleData?.tag?.map((item) => item?.title);
        }
      }

      gtmUtils.pushEvent(gtmData);
    }, 500);
  }, []);

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,minimum-scale=1"
      />
      <title>{meta_title}</title>
      <meta name="description" content={meta_description || ""} />
      <meta name="keywords" content={meta_keywords || ""} />
      {process_og_url &&
        isValidCanonicalizeUrl(process_og_url) &&
        process_canonical_url && (
          <>
            <link
              rel="canonical"
              href={process_canonical_url}
              key="canonical"
            />
            <meta key="og:url" property="og:url" content={process_og_url} />
          </>
        )}
      <meta key="og:title" property="og:title" content={og_title} />
      <meta key="twitter:title" property="twitter:title" content={og_title} />
      <meta
        key="og:description"
        property="og:description"
        content={og_description}
      />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={og_description}
      />
      <meta key="twitter:site" name="twitter:site" content="@fwd" />
      <meta key="twitter:card" name="twitter:card" content="summary" />
      <meta
        key="og:image:secure_url"
        property="og:image:secure_url"
        content={og_image_url}
      />
      <meta key="og:image" property="og:image" content={og_image_url} />
      <meta key="og:image:width" property="og:image:width" content="640" />
      <meta key="og:image:height" property="og:image:height" content="442" />
      <meta
        key="og:type"
        property="og:type"
        content={pageType === PageType.Articles ? "article" : "website"}
      />
      <meta
        key="twitter:image"
        name="twitter:image"
        content={twitter_og_image_url}
      />
      {(currentEnv === Environment.UAT || currentEnv === Environment.DEV) && (
        <>
          <meta
            name="google-site-verification"
            content="ATLEwMj34qsV9JbVpbd0iurOCHQVrxh_Mr5LQ_7mF9c"
          />
          <meta key="robots" name="robots" content="noindex, nofollow" />
        </>
      )}

      <link rel="icon" href="/favicon.ico" />
      {!isEmpty(ld_json) && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(ld_json),
          }}
        />
      )}
    </Head>
  );
};

export default HeaderMetadata;
