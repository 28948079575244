import { getWindowHref } from "@utils";
import { maskSensitiveData } from "./gmtHash";
import { isFile } from "./string";
import { useContext } from "react";

const _dataLayerPush = (data: any) => {
  //@ts-ignore
  if (typeof window !== "undefined" && !!window?.dataLayer) {
    //@ts-ignore
    window.dataLayer?.push(maskSensitiveData(data));
  }
};

const push = (eventObject: any, event?: string) => {
  _dataLayerPush({
    event,
    content: {
      url: getWindowHref(),
      ...eventObject,
    },
  });
};

const pageView = (eventObject: any) => {
  _dataLayerPush({
    event: gaEvent.page_view,
    page_url: getWindowHref(),
    ...eventObject,
  });
};

const leadForm = (eventObject: any) => {
  _dataLayerPush({
    event: gaEvent.lead_form,
    ...eventObject,
  });
};

const viewItemList = (eventObject: any) => {
  _dataLayerPush({
    event: gaEvent.view_item_list,
    ...eventObject,
  });
};

const selectItem = (eventObject: any) => {
  _dataLayerPush({
    event: gaEvent.select_item,
    ...eventObject,
  });
};

const viewItem = (eventObject: any) => {
  _dataLayerPush({
    event: gaEvent.view_item,
    ecommerce: {
      items: [eventObject]
    }
  });
};

const navigation = (eventObject: any) => {
  _dataLayerPush({
    event: gaEvent.navigation,
    ...eventObject,
  });
};

const fileDownload = (eventObject: any) => {
  _dataLayerPush({
    ...eventObject,
    event: gaEvent.file_download,
  });
};


const buttonClick = (eventObject: any) => {
  if (!eventObject) return;
  if (!eventObject.gaEvent && isFile(eventObject?.destinationUrl)) {
    const buttonTitle = eventObject?.destinationUrl.substring(eventObject?.destinationUrl.lastIndexOf('/') + 1);
    eventObject = { ...eventObject, buttonTitle };
  }
  delete eventObject.gaEvent;
  _dataLayerPush({
    event: gaEvent.button_click,
    ...eventObject,
  });
};

export const gaEvent = {
  content_landing: "content_landing",
  content_type_home: "homepage",
  button_click: "buttonClick",
  open_chat: "open_chat",
  close_chat: "close_chat",
  page_view: "page_view",
  lead_form: "lead_form",
  view_item_list: "view_item_list",
  select_item: "select_item",
  navigation: "navigation",
  view_item: "view_item",
  file_download: "file_download"
};


export const gaEventAction = {
  contact_us: "contact_us",
  button_click: "button_click",
  filter: "filter"
};

export default {
  push,
  buttonClick,
  pushEvent: _dataLayerPush,
  gaEvent,
  pageView,
  leadForm,
  viewItemList,
  selectItem,
  navigation,
  viewItem,
  fileDownload
};
