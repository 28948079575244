import { colorsUtils } from "@d2c-ui-components-react";
import { fwdColors as fwdColorsLib } from "@fwd-dep/nextgen-ui-lib";

export const fwdColors = {
  ...fwdColorsLib,
  orange20: "#FAE4D3",
  orange5: colorsUtils.fwdOrangeLight,
  orange50: "#FFF0E4",
  fwdOrange50: "#F3BB90",

  grey50: colorsUtils.fwdGrey50,

  blue: "#0097A9",
  blue20: "#CCEAEE",
  grey20: "#F8F9F9",
  grey30: "#DBDFE1",
};
